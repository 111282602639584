<template>
  <div>
    <div class="container-fluid bg-content">
      <v-row>
        <v-col
          v-for="(item, index) in 12"
          :key="index"
          cols="12"
          lg="3"
          md="3"
          sm="4"
        >
          <div>
            <div class="content-photo pb-9 pt-9">
              <div class="display-flex align-items-center justify-content-center">
                <div>
                  <div class="display-flex align-items-center justify-content-center">
                    <v-icon
                      color="#B7B7B7"
                      size="18px"
                    >
                      mdi-paperclip
                    </v-icon>
                  </div>
                  <p class="text-grag-file">{{ texts.warehouse.photographs.dragFile }}</p>
                  <p class="text-or">{{ texts.warehouse.photographs.or }}</p>
                  <v-btn
                    class="button-yellow mon-bold mt-2"
                    elevation="0"
                  >
                    {{ texts.warehouse.photographs.selectFile }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="separator-line" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="content-button-flex-end">
            <v-btn
              class="button-transparent mon-regular"
              elevation="0"
            >
              {{ texts.warehouse.photographs.buttonClose }}
            </v-btn>
            <v-btn
              class="button-yellow mon-bold ml-5"
              elevation="0"
            >
              {{ texts.warehouse.photographs.buttonAddWarehouse }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditWarehouseTabContent3Layout",
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
};
</script>

<style scoped>
.text-grag-file {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #b7b7b7;
  opacity: 1;
  margin-bottom: 0px;
}

.separator-line {
  border: 1px solid #cecece;
}

.text-or {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #b7b7b7;
  opacity: 1;
  margin-bottom: 0px;
}

.content-photo {
  border: 1px dashed var(--unnamed-color-707070);
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
}

.content-button-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-transparent {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}

.button-yellow {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}

.button-transparent {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}

.button-yellow {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}
</style>